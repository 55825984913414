<template>
  <main>
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"
              ><a>{{ $t('breadcrumb_home') }}</a></router-link
            >
            <router-link tag="li" :to="{ name: 'auth' }"
              ><a>{{ $t('auth_bc') }}</a></router-link
            >
          </ul>
        </nav>
      </div>
    </section>
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <div class="field">
          <label class="label">{{ $t('auth_user') }}</label>
          <pre>{{ user }}</pre>
        </div>
        <div class="field">
          <label class="label">{{ $t('auth_claims') }}</label>
          <pre>{{ claims }}</pre>
        </div>
        <sqr-error-note class="field" :error="signInError" />
        <div class="field">
          <label class="label">{{ $t('auth_custom_login') }}</label>
          <div class="field has-addons">
            <div class="control">
              <input class="input" type="text" v-model="customToken" />
            </div>
            <div class="control">
              <a
                class="button is-primary"
                :class="{ 'is-loading': signingIn }"
                @click="signIn()"
              >
                {{ $t('auth_custom_login') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex';
import SqrErrorNote from '@/sqrd/SqrErrorNote';
export default {
  name: 'Auth',
  components: {
    SqrErrorNote,
  },
  computed: {
    ...mapState('auth', ['user', 'claims']),
  },
  data() {
    return {
      customToken: '',
      signingIn: false,
      signInError: null,
    };
  },
  methods: {
    async signIn() {
      try {
        this.signingIn = false;
        this.signInError = null;
        const custom = this.customToken;
        await this.$fb().auth().signInWithCustomToken(custom);
        this.customToken = '';
      } catch (error) {
        this.signInError = error;
      } finally {
        this.signingIn = false;
      }
    },
  },
};
</script>
